import { Injectable } from '@angular/core';
import { User } from './user';
import { Team } from './team';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  endpoint: string = 'https://api.ttcmomalle.be';
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, public router: Router) { }

  // Get teams
  getTeams(): Observable<any> {
    let api = `${this.endpoint}/teams`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res || {}
      }),
      //catchError(this.handleError)
    )
  }

  getTeam(id): Observable<any> {
    let api = `${this.endpoint}/teams/${id}`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res || {}
      }),
      //catchError(this.handleError)
    )
  }

  // Get events
  getEvents(idTeam): Observable<any> {
    let api = `${this.endpoint}/teams/${idTeam}/events`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res || {}
      }),
      //catchError(this.handleError)
    )
  }

  // Get events
  putEvent(event) {
    console.log("update");
    let api = `${this.endpoint}/update-event/${event._id}`;
    this.http.put(api, event, { headers: this.headers }).subscribe(res => {
      console.log(res)
  });
  }

   // Get events
   deleteEvent(event): Observable<any> {
    let api = `${this.endpoint}/delete-event/${event}`;
    return this.http.delete(api, { headers: this.headers })
  }

  // Sign-up
  createEvent(event: Event): Observable<any> {
    let api = `${this.endpoint}/create-event`;
    return this.http.post(api, event)
      .pipe(
        //catchError(this.handleError)
      )
  }

// Sign-up
  editEvent(event: Event, id): Observable<any> {
    let api = `${this.endpoint}/update-event/${id}`;
    return this.http.put(api, event)
      .pipe(
        //catchError(this.handleError)
      )
  }

// Get events
  createComment(event, userId, firstname, comment) {
    let api = `${this.endpoint}/events/${event._id}/create-comment`;
    let eventId = event._id;
    this.http.post(api, {eventId, userId, firstname, comment}, { headers: this.headers }).subscribe(res => {
      console.log(res)
  });
  }
}
