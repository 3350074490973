import { Component, OnInit } from '@angular/core';

import { TeamsService } from '../../shared/teams.service';
import { Team } from 'src/app/shared/team';
import { Router } from '@angular/router';
import { exit } from 'process';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  teams: Team[] = [];
  currentId = localStorage.getItem('user_id');
  display = [];
  events: Event[] = [];

  constructor(public teamsService: TeamsService, public router: Router) {
    if(!localStorage.getItem('user_id'))
    {
      router.navigate(['/log-in']);
    }

    this.teamsService.getTeams().subscribe(res => {
      this.teams = res;
      this.teams.sort(this.tri);

      this.teams.forEach(team => {
        this.teamsService.getEvents(team._id).subscribe(res => {
          let events = res.msg;
          //this.events.push(events[0]);
        });
      });

      console.log(this.events);
    });
  }

  tri(a,b)
  {
  return (a.name > b.name)?1:-1;
  }


  openModalMembers(id) {
    this.display[id] = "block";
  }
  onCloseHandled(id) {
    this.display[id] = "none";
  }

  ngOnInit() {
    for(var team of this.teams)
    {
      this.display["" + team._id] = "none";
    }
  }

}
