import { User } from './user';

export class Comment {
    _id: String;
    firstname: String;
    id: String;
    comment: String;

    constructor(firstname, id, comment) {
        this.firstname = firstname;
        this.id = id;
        this.comment = comment;
    }
}