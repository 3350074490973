import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { TeamsService } from './shared/teams.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  currentUser: Object = {};
  teams: Object[] = [];

  display = "none";
  menu = "Menu";

  screenWidth;
  screenHeight;

  constructor(private router: Router, public authService: AuthService, public teamsService: TeamsService, private actRoute: ActivatedRoute) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  openModalMenu() {
    if(this.screenWidth < 1080)
    {
      if(this.display == "none")
      {
        this.display = "block";
        this.menu = "Fermer";
      }
      else
      {
        this.display = "none";
        this.menu = "Menu";
      }
    }
  }

  ngOnInit(): void {
    this.authService.getUserProfile(localStorage.getItem('user_id')).subscribe(res => {
      this.currentUser = res.msg;
    })

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    if(this.screenWidth >= 1080)
    {
      this.display = "block";
    }
    else
    {
      this.display = "none"
    }

    if(typeof this.currentUser == 'undefined')
    {
      this.logout();
    }

    this.teamsService.getTeams().subscribe(res => {
      this.teams = res;
      this.teams.sort(this.tri);
    })
  }

  tri(a,b)
  {
  return (a.name > b.name)?1:-1;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    if(this.screenWidth >= 1080)
    {
      this.display = "block";
    }
    else
    {
      this.display = "none"
    }
  }

  logout() {
    this.authService.doLogout()
  }

}