import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamsService } from '../../shared/teams.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { DOCUMENT, formatDate } from '@angular/common';

import { Event } from '../../shared/event';
import { Team } from '../../shared/team';
import { User } from '../../shared/user';
import { Comment } from '../../shared/comment';
import { NumberValueAccessor } from '@angular/forms';
import { isDefined } from '@angular/compiler/src/util';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css'],
})
export class TeamsComponent implements OnInit {

  currentUser: User;
  currentId = localStorage.getItem('user_id');
  team: Team = null;

  events: Event[] = [];
  events_history: Event[] = [];
  events_users_wait: User[][] = [];

  latitude: number;
  longitude: number;
  zoom: number;

  winEvent = [];

  i: Number = 0;
  display = "none";
  displayMembers = "none";
  displayConv = [];
  displayEditEvent = "none";

  pourcentPerUsers:number[] = [];

  eventUpdate: Event;

  displayEvent = "none";
  
  showCom: String[][] = [];

  active_calendar = "active";
  active_history = "";

  newEventForm: FormGroup;
  editEventForm: FormGroup;

  newCommentForm: FormGroup;
  newConvForm: FormGroup;
  newScoreForm: FormGroup;

  users_conv: User[][] = [[]];

  constructor(public fb: FormBuilder, private router: Router, public authService: AuthService, private actRoute: ActivatedRoute, public teamsService: TeamsService,  @Inject(DOCUMENT) private _document: Document) {
    this.newEventForm = this.fb.group({
      team: [''],
      opponent: [''],
      start_date: [''],
      start_time: [''],
      arrival_time: [''],
      address: [''],
      where: ['']
    })

    this.editEventForm = this.fb.group({
      type_update: [''],
      message_update: [''],
      team: [''],
      opponent: [''],
      start_date: [''],
      start_time: [''],
      arrival_time: [''],
      address: ['']
    })

    this.newCommentForm = this.fb.group({
      comment: ['']
    })

    this.newConvForm = this.fb.group({
      users: ['']
    })

    this.newScoreForm = this.fb.group({
      scoreD: Number,
      scoreE: Number
    })
  }

  ngOnInit() {
    this.authService.getUserProfile(localStorage.getItem('user_id')).subscribe(res => {
      this.currentUser = res.msg;
    })
    
    let id = this.actRoute.snapshot.paramMap.get('id');
    this.teamsService.getTeam(id).subscribe(res => {
      this.team = res.msg;

      this.teamsService.getEvents(id).subscribe(res => {
        let eventst = res.msg;
        eventst.forEach(event => {
          event = new Event(event);

          this.displayConv[event._id] = "none";
          let hm = event.start_time.split(':');
          let hours = parseInt(hm, 10);
          let minutes = parseInt(hm, 10);

          let dateNow = new Date();

          let testDate = formatDate(event.start_date,'yyyy-MM-dd','en_US') <= formatDate(new Date(),'yyyy-MM-dd','en_US');

          if(testDate && (hours && hours < dateNow.getHours() || (hours === dateNow.getHours() && (minutes && minutes <= dateNow.getMinutes()))))
            event.passed = "Y";
          else
            event.passed = "N";

          if(!event.where && event.score_d > event.score_e || event.where && event.score_d < event.score_e)
            this.winEvent[event._id] = "rgba(0, 255, 0, 0.3)";
          else if(event.score_d === event.score_e)
            this.winEvent[event._id] = "false";
          else
          this.winEvent[event._id] = "rgba(255, 0, 0, 0.4)";

          // block matchs for stop competition between 11/10 and ...
          if((formatDate(event.start_date,'yyyy-MM-dd','en_US') >= formatDate(new Date(),'yyyy-MM-dd','en_US')))
          {   
            this.events.push(event);
          }
          else
          {
            if(
              (formatDate(event.start_date,'yyyy','en_US') == formatDate(new Date(),'yyyy','en_US') && (formatDate(new Date(),'MM','en_US') < '07' && formatDate(event.start_date,'MM','en_US') >= '01'))
              || (formatDate(event.start_date,'yyyy','en_US') == formatDate(new Date(),'yyyy','en_US') && (formatDate(new Date(),'MM','en_US') >= '07' && formatDate(event.start_date,'MM','en_US') >= '07'))
              || ((parseInt(formatDate(event.start_date,'yyyy','en_US')) + 1 == parseInt(formatDate(new Date(),'yyyy','en_US')) && formatDate(new Date(),'MM','en_US') < '07' && formatDate(event.start_date,'MM','en_US') >= '07'))
              
              
              //((parseInt(formatDate(event.start_date,'yyyy','en_US')) + 1 == parseInt(formatDate(new Date(),'yyyy','en_US')) && formatDate(event.start_date,'MM','en_US') > '07'))
            )
            {
              this.events_history.push(event);
            }
          }
            
          this.users_conv[event._id] = event.users_summoned.slice();

          this.showCom[event._id] = [];
          event.users_available.forEach(user => {
            this.showCom[event._id][user._id] = "false";
          });
          event.users_unavailable.forEach(user => {
            this.showCom[event._id][user._id] = "false";
          });
          event.users_perhaps.forEach(user => {
            this.showCom[event._id][user._id] = "false";
          });

          event.users_summoned.sort((a, b) => a['rank'] > b['rank'] ? 1 : a['rank'] === b['rank'] ? 0 : -1);

        });

        this.events_history.forEach(event => {
          event.users_summoned.forEach(user => {
            if(!this.pourcentPerUsers[user._id])
              this.pourcentPerUsers[user._id] = 1;
            else
            this.pourcentPerUsers[user._id] += 1;
          });
        });

        this.events_history.reverse();

        /*this.teamsService.getEventsHistory(id).subscribe(res => {
          let eventstHistory = res.msg;
          eventstHistory.forEach(event => {
            this.events_history.push(new Event(event));
          });*/

          //if(this.events.history)
  
          this.setWaitUserEvents();
        //});
      });
    });

    //this.setCurrentLocation();
  }

  calendar() {
    this.active_calendar = "active";
    this.active_history = "";
  }

  history() {
    this.active_calendar = "";
    this.active_history = "active";
  }

  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }

  openModalMembers(team) {
    this.displayMembers = "block";
  }
  onCloseHandledMembers() {
    this.displayMembers = "none";
  }

  openModalConv(id) {
    this.displayConv[id] = "block";
  }
  onCloseHandledConv(id) {
    this.displayConv[id] = "none";
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  private setWaitUserEvents() {
    /*this.team.users.forEach(user => {
      console.log(user);
    });*/

    let i=0;
    this.events.forEach(event => {
      this.events_users_wait['' + event._id] = [];
      this.team.users.forEach(user => {
        if(event.users_available.filter(elem => elem._id === user._id).length === 0 && event.users_unavailable.filter(elem => elem._id === user._id).length === 0 && event.users_perhaps.filter(elem => elem._id === user._id).length === 0)
        {
          this.events_users_wait['' + event._id].push(user);
        }
      });
      i++;
    });

    console.log(this.events);
  }

  public findUser(table, id)
  {
    return table.filter(elem => elem._id === id);
  }

  public findUserByFirstname(table, firstname)
  {
    return table.filter(elem => elem.firstname === firstname);
  }

  public setAvailable(event, y)
  {

    if(event.users_available.filter(elem => elem._id === this.currentId).length === 0)
    {
      event.users_available.push(this.currentUser);

      if(event.users_unavailable.filter(elem => elem._id === this.currentId).length !== 0)
      {
        let i=0;
        event.users_unavailable.forEach(user => {
          if(user._id === this.currentId)
          {
            event.users_unavailable.splice(i, 1)
          }
          i++;
        });
      }

      if(event.users_perhaps.filter(elem => elem._id === this.currentId).length !== 0)
      {
        let i=0;
        event.users_perhaps.forEach(user => {
          if(user._id === this.currentId)
          {
            event.users_perhaps.splice(i, 1)
          }
          i++;
        });
      }

      this.teamsService.putEvent(event);

      let i=0;
      this.events_users_wait[y].forEach(user => {
        if(user._id === this.currentId)
        {
          this.events_users_wait[y].splice(i, 1)
        }
        i++;
      });
    }
  }

  public setUnavailable(event, y)
  {
    if(event.users_unavailable.filter(elem => elem._id === this.currentId).length === 0)
    {
      event.users_unavailable.push(this.currentUser);

      if(event.users_available.filter(elem => elem._id === this.currentId).length !== 0)
      {
        let i=0;
        event.users_available.forEach(user => {
          if(user._id === this.currentId)
          {
            event.users_available.splice(i, 1)
          }
          i++;
        });
      }

      if(event.users_perhaps.filter(elem => elem._id === this.currentId).length !== 0)
      {
        let i=0;
        event.users_perhaps.forEach(user => {
          if(user._id === this.currentId)
          {
            event.users_perhaps.splice(i, 1)
          }
          i++;
        });
      }

      this.teamsService.putEvent(event);

      let i=0;
      this.events_users_wait[y].forEach(user => {
        if(user._id === this.currentId)
        {
          this.events_users_wait[y].splice(i, 1)
        }
        i++;
      });
    }
  }

  public setPerhaps(event, y)
  {
    if(event.users_perhaps.filter(elem => elem._id === this.currentId).length === 0)
    {
      event.users_perhaps.push(this.currentUser);

      if(event.users_available.filter(elem => elem._id === this.currentId).length !== 0)
      {
        let i=0;
        event.users_available.forEach(user => {
          if(user._id === this.currentId)
          {
            event.users_available.splice(i, 1)
          }
          i++;
        });
      }

      if(event.users_unavailable.filter(elem => elem._id === this.currentId).length !== 0)
      {
        let i=0;
        event.users_unavailable.forEach(user => {
          if(user._id === this.currentId)
          {
            event.users_unavailable.splice(i, 1)
          }
          i++;
        });
      }

      this.teamsService.putEvent(event);

      let i=0;
      this.events_users_wait[y].forEach(user => {
        if(user._id === this.currentId)
        {
          this.events_users_wait[y].splice(i, 1)
        }
        i++;
      });
    }
  }

  createEvent() {
    this.newEventForm.value.team = this.team._id;
    this.teamsService.createEvent(this.newEventForm.value).subscribe((res) => {
      if (res.result) {
        console.log(res)
        this.newEventForm.reset()
        this.router.navigate([this.router.url]);
      }
    })
  }

  edittEvent(event) {
    this.editEventForm.value.team = event.team._id;
    /*this.editEventForm.value.opponent.length > 0 ? this.editEventForm.value.opponent : event.opponent;
    this.editEventForm.value.start_date.length > 0 ? this.editEventForm.value.start_date : event.start_date;
    this.editEventForm.value.start_time != '' ? this.editEventForm.value.start_time : event.start_time;
    this.editEventForm.value.arrival_time != '' ? this.editEventForm.value.arrival_time : event.arrival_time;
    this.editEventForm.value.address != '' ? this.editEventForm.value.address : event.address;*/

    if(this.editEventForm.value.type_update.length == 0)
      this.editEventForm.value.type_update = event.type_update;
    if(this.editEventForm.value.message_update.length == 0)
      this.editEventForm.value.message_update = event.message_update;
    if(this.editEventForm.value.opponent.length == 0)
      this.editEventForm.value.opponent = event.opponent;
    if(this.editEventForm.value.start_date.length == 0)
      this.editEventForm.value.start_date = event.start_date;
    if(this.editEventForm.value.start_time.length == 0)
      this.editEventForm.value.start_time = event.start_time;
    if(this.editEventForm.value.arrival_time.length == 0)
      this.editEventForm.value.arrival_time = event.arrival_time;
    if(this.editEventForm.value.address.length == 0)
      this.editEventForm.value.address = event.address;

    this.teamsService.editEvent(this.editEventForm.value, event._id).subscribe((res) => {
      if (res) {
        this.editEventForm.reset()
        this.router.navigate([this.router.url]);
      }
    })
  }

  deleteEvent(event) {
    this.teamsService.deleteEvent(event._id).subscribe((res) => {
      if (res) {
        this.router.navigate([this.router.url]);
      }
    })
  }

  public createComment(event, id, firstname, exist)
  {
      if(!exist && this.newCommentForm.value.comment.length > 0)
      {
        this.teamsService.createComment(event, id, firstname, this.newCommentForm.value.comment);
        this.events.forEach(eventt => {
          if(eventt._id == event._id)
            eventt.comments.push(new Comment(firstname, id, this.newCommentForm.value.comment));
          else
            this.events_history.push(new Event(event));
        });
      }

      this.newCommentForm.reset()
      //else
        //this.teamsService.putComment(event, firstname, this.newCommentForm.value.comment);
  }

  public addScore(event)
  {
    event.score_d = this.newScoreForm.value.scoreD;
    event.score_e = this.newScoreForm.value.scoreE;
      
    this.teamsService.putEvent(event);
    this.newScoreForm.reset()
  }

  setConv(event)
  {
    event.users_summoned = this.users_conv[event._id];
    this.teamsService.putEvent(event);
  }

  userConv(event, user, type)
  {
    /*if(!isDefined(this.users_conv[event._id]))
    {
      this.users_conv[event._id] = [];
    }*/

    if(type == 1) {
      this.users_conv[event._id].push(user);
    }
    else {
      let i=0;
      this.users_conv[event._id].forEach(usert => {
        if(usert._id === user._id)
          this.users_conv[event._id].splice(i, 1)

        i++;
      });
    }

    this.users_conv[event._id].sort((a, b) => a['rank'] > b['rank'] ? 1 : a['rank'] === b['rank'] ? 0 : -1);
  }

  showComt(event, user)
  {
    this.showCom[event][user] = "true";
  }
  
  hideCom(event, user)
  {
    this.showCom[event][user] = "false";
  }

  editEvent(event)
  {
    if(this.displayEditEvent == "none")
      this.displayEditEvent = "block";
    else
      this.displayEditEvent = "none";
  }

  showEditEvent(event)
  {
    this.eventUpdate = new Event(event);
    this.displayEvent = "block";
  }

  onCloseEditEvent(event)
  {
    this.displayEvent = "none";
    this.eventUpdate = null;
  }
}
