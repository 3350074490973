import { User } from './user';
import { Comment } from './comment';
import { Time } from '@angular/common';

export class Event {
    _id: String;
    team: String;
    opponent: String;
    start_date: Date;
    start_time: Time;
    arrival_time: Time;
    during_time: Number;
    address: String;
    where: Boolean;
    users_available: User[];
    users_unavailable: User[];
    users_perhaps: User[];
    users_summoned: User[];
    users_undefined: User[];
    users_wait: User[];
    comments: Comment[];
    type_update: Number;
    message_update: String;
    passed: string;
    score_e: Number;
    score_d: Number;
    wait: boolean;

    constructor(event) {
        this._id = event._id;
        this.team = event.team;
        this.opponent = event.opponent;
        this.start_date = event.start_date;
        this.start_time = event.start_time;
        this.arrival_time = event.arrival_time;
        this.during_time = event.during_time;
        this.address = event.address;
        this.where = event.where;
        this.users_available = event.users_available;
        this.users_unavailable = event.users_unavailable;
        this.users_perhaps = event.users_perhaps;
        this.users_summoned = event.users_summoned;
        this.users_wait = [];
        this.comments = event.comments;
        this.type_update = event.type_update;
        this.message_update = event.message_update;
        this.score_d = event.score_d;
        this.score_e = event.score_e;
    }
}